import React from 'react';
import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <h1 className="App-title">
            vlg
          </h1>
          <p className="App-title">
            sharing very soon
          </p>
        </header>
      </div>
  );
}

export default App;
